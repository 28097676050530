import React, { Component } from 'react'
import { Link } from '../../link'
import { Carousel } from 'react-responsive-carousel'
import { Consumer } from '../../../store/createContext'
import 'react-responsive-carousel/lib/styles/carousel.min.css'

import { Section } from '../index'
import { Grid } from '../../grid'

import styled from 'styled-components'
import { colors } from '../../../styles/colors'

const Quote = styled.div`
  p {
    font-style: italic;
    padding: 0 24px;
  }
  h6 {
    color: ${colors.quaternary.default};
    text-align: right;
  }
`

const Image = styled.div`
  background-image: url("${props => props.src}");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding-top: 66%;
`

const SectionHeading = styled.p`
  color: ${colors.quaternary.default};
`

const ProjectHeading = styled.h3`
  margin-bottom: 10px;

  a {
    text-decoration: none;
    color: ${props => (props.dark ? '#FFF' : colors.primary.default)};
  }
`

const CarouselWrapper = styled.div`
  max-width: 100%;
  overflow: hidden;
  height: 100%;
  position: relative;

  @media (min-width: 992px) {
    margin-right: 60px;
  }

  .carousel .slide {
    text-align: left;
    background: transparent;
  }
`

const ImageCarousel = styled.div`
  position: relative;
  margin-left: -20px;
  margin-right: -20px;

  .carousel .slide {
    background: transparent;
  }

  @media (min-width: 992px) {
    margin-left: -30px;
    margin-right: 0;
  }
`

const ImageControls = styled.div`
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    display: flex;
    border: none;
    background-color: transparent;

    svg {
      filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.4));
    }

    &:hover:not(:disabled) {
      cursor: pointer;
    }

    &:focus {
      outline: none;
    }
  }

  ul {
    display: inline-flex;
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      display: inline-block;
      align-items: center;
    }

    button {
      display: block;
      background-color: #fff;
      border-radius: 1000px;
      height: 8px;
      width: 8px;
      padding: 0;
      margin: 0 4px;
      filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.3));

      &.active {
        background-color: ${colors.quaternary.default};
      }
    }
  }
`

const SliderControls = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  bottom: 0;
  min-width: 100%;
  max-width: 100%;
  text-align: center;

  @media (min-width: 769px) {
    position: absolute;
  }

  button {
    appearance: none;
    border: none;
    background: transparent;

    &:active,
    &:focus {
      outline: none;
    }

    &:hover:not(:disabled) {
      cursor: pointer;
    }

    &.next {
      text-align: right;
    }

    &.prev {
      text-align: left;
    }
  }

  h6 {
    margin-bottom: 0;
  }
`

const createTags = tags => {
  let tagString = ''

  tags.map((tag, i) => {
    tagString = tagString + tag
    if (tags.length !== i + 1) tagString = tagString + ', '
  })

  return tagString
}

const createCaseStudySlug = fileName => {
  return fileName
    .split('/')
    .pop()
    .replace('.md', '')
}

export class CaseStudySlider extends Component {
  state = {
    activeCaseStudy: 0,
    activeImage: 0,
  }

  // Image Controls
  nextImage = () => {
    const totalImages = this.props.caseStudies[this.state.activeCaseStudy].node
      .frontmatter.images.length
    this.setState({
      activeImage:
        this.state.activeImage === totalImages - 1
          ? 0
          : this.state.activeImage + 1,
    })
  }

  prevImage = () => {
    const totalImages = this.props.caseStudies[this.state.activeCaseStudy].node
      .frontmatter.images.length

    this.setState({
      activeImage:
        this.state.activeImage === 0
          ? totalImages - 1
          : this.state.activeImage - 1,
    })
  }

  updateActiveImage = index => {
    const { activeImage } = this.state
    const totalImages = this.props.caseStudies[this.state.activeCaseStudy].node
      .frontmatter.images.length

    if (activeImage !== index) {
      this.setState({
        activeImage:
          index > totalImages - 1 ? 0 : index < 0 ? totalImages - 1 : index,
      })
    }
  }

  changeImage = index => {
    this.setState({
      activeImage: index,
    })
  }

  // Case Study Controls
  next = () => {
    this.setState({
      activeImage: 0,
      activeCaseStudy:
        this.props.caseStudies.length - 1 === this.state.activeCaseStudy
          ? 0
          : this.state.activeCaseStudy + 1,
    })
  }

  prev = () => {
    this.setState({
      activeImage: 0,
      activeCaseStudy:
        this.state.activeCaseStudy === 0
          ? this.props.caseStudies.length - 1
          : this.state.activeCaseStudy - 1,
    })
  }

  updateactiveCaseStudy = index => {
    const { activeCaseStudy } = this.state

    if (activeCaseStudy !== index) {
      this.setState({
        activeImage: 0,
        activeCaseStudy:
          index > this.props.caseStudies.length - 1
            ? 0
            : index === 0
            ? this.props.caseStudies.length - 1
            : index,
      })
    }
  }

  render() {
    const { props } = this

    return (
      <Consumer>
        {({ dark }) => (
          <Section>
            <Grid constrain={'1260px'}>
              <Grid desktop={{ columns: '2fr 1fr' }} constrain={'1260px'}>
                <ImageCarousel>
                  <Carousel
                    showThumbs={false}
                    showArrows={false}
                    showStatus={false}
                    showIndicators={false}
                    infiniteLoop={true}
                    selectedItem={this.state.activeImage}
                    onChange={this.updateActiveImage}
                  >
                    {props.caseStudies[
                      this.state.activeCaseStudy
                    ].node.frontmatter.images.map((image, i) => (
                      <div key={i}>
                        <Image src={image} />
                      </div>
                    ))}
                  </Carousel>
                  <ImageControls>
                    <button
                      className="prev"
                      onClick={this.prevImage}
                      style={{ marginRight: 4 }}
                    >
                      <svg
                        width="10px"
                        height="16px"
                        viewBox="0 0 10 16"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                      >
                        <g
                          id="Home"
                          stroke="none"
                          strokeWidth="1"
                          fill="none"
                          fillRule="evenodd"
                        >
                          <g
                            transform="translate(-997.000000, -948.000000)"
                            id="//-PROJECT-//"
                            stroke="#FFF"
                          >
                            <g transform="translate(0.000000, 431.000000)">
                              <g id="Coastal">
                                <g
                                  id="Project-Nav"
                                  transform="translate(990.000000, 511.000000)"
                                >
                                  <g
                                    id="Left-Arrow"
                                    transform="translate(12.000000, 14.000000) rotate(-360.000000) translate(-12.000000, -14.000000) translate(0.000000, 2.000000)"
                                  >
                                    <polyline
                                      id="Path-2"
                                      transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000) "
                                      points="4.5 15.75 12 8.25 19.5 15.75"
                                    />
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </svg>
                    </button>
                    <ul>
                      {props.caseStudies[
                        this.state.activeCaseStudy
                      ].node.frontmatter.images.map((item, i) => (
                        <li key={i}>
                          <button
                            onClick={() => this.changeImage(i)}
                            className={
                              this.state.activeImage === i ? 'active' : ''
                            }
                          />
                        </li>
                      ))}
                    </ul>
                    <button
                      className="next"
                      onClick={this.nextImage}
                      style={{ marginLeft: 4 }}
                    >
                      <svg
                        width="10px"
                        height="16px"
                        viewBox="0 0 10 16"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                      >
                        <defs></defs>
                        <g
                          id="Home"
                          stroke="none"
                          strokeWidth="1"
                          fill="none"
                          fillRule="evenodd"
                        >
                          <g
                            transform="translate(-1333.000000, -946.000000)"
                            id="//-PROJECT-//"
                            stroke="#FFF"
                          >
                            <g transform="translate(0.000000, 431.000000)">
                              <g id="Coastal">
                                <g
                                  id="Project-Nav"
                                  transform="translate(990.000000, 511.000000)"
                                >
                                  <g
                                    id="Right-Arrow"
                                    transform="translate(348.000000, 12.000000) rotate(-180.000000) translate(-348.000000, -12.000000) translate(336.000000, 0.000000)"
                                  >
                                    <polyline
                                      id="Path-2"
                                      transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000) "
                                      points="4.5 15.75 12 8.25 19.5 15.75"
                                    />
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </svg>
                    </button>
                  </ImageControls>
                </ImageCarousel>
                <CarouselWrapper>
                  <SectionHeading className="blockquote">
                    Discover Recent Projects
                  </SectionHeading>
                  <Carousel
                    showThumbs={false}
                    showArrows={false}
                    showStatus={false}
                    showIndicators={false}
                    infiniteLoop={true}
                    selectedItem={this.state.activeCaseStudy}
                    onChange={this.updateactiveCaseStudy}
                  >
                    {props.caseStudies.map((caseStudy, i) => (
                      <div key={i}>
                        <ProjectHeading dark={dark}>
                          <Link
                            to={`/work/${createCaseStudySlug(
                              caseStudy.node.fileAbsolutePath
                            )}`}
                          >
                            {caseStudy.node.frontmatter.title}
                          </Link>
                        </ProjectHeading>
                        <h6>{createTags(caseStudy.node.frontmatter.tags)}</h6>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: caseStudy.node.html,
                          }}
                        />
                        {caseStudy.node.frontmatter.quote.quote && (
                          <Quote>
                            <p>"{caseStudy.node.frontmatter.quote.quote}"</p>
                            {caseStudy.node.frontmatter.quote.name && (
                              <h6>
                                {caseStudy.node.frontmatter.quote.name}
                                {caseStudy.node.frontmatter.quote.title &&
                                  `<br />${caseStudy.node.frontmatter.quote.title}`}
                              </h6>
                            )}
                          </Quote>
                        )}
                      </div>
                    ))}
                  </Carousel>
                  <SliderControls>
                    <button className="prev" onClick={this.prev}>
                      <svg
                        width="10px"
                        height="16px"
                        viewBox="0 0 10 16"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                      >
                        <g
                          id="Home"
                          stroke="none"
                          stroke-width="1"
                          fill="none"
                          fill-rule="evenodd"
                        >
                          <g
                            transform="translate(-997.000000, -948.000000)"
                            id="//-PROJECT-//"
                            stroke={dark ? '#FFF' : colors.primary.default}
                          >
                            <g transform="translate(0.000000, 431.000000)">
                              <g id="Coastal">
                                <g
                                  id="Project-Nav"
                                  transform="translate(990.000000, 511.000000)"
                                >
                                  <g
                                    id="Left-Arrow"
                                    transform="translate(12.000000, 14.000000) rotate(-360.000000) translate(-12.000000, -14.000000) translate(0.000000, 2.000000)"
                                  >
                                    <polyline
                                      id="Path-2"
                                      transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000) "
                                      points="4.5 15.75 12 8.25 19.5 15.75"
                                    ></polyline>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </svg>
                    </button>
                    <h6>
                      {this.state.activeCaseStudy + 1} /{' '}
                      {props.caseStudies.length}
                    </h6>
                    <button className="next" onClick={this.next}>
                      <svg
                        width="10px"
                        height="16px"
                        viewBox="0 0 10 16"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                      >
                        <g
                          id="Home"
                          stroke="none"
                          strokeWidth="1"
                          fill="none"
                          fillRule="evenodd"
                        >
                          <g
                            transform="translate(-1333.000000, -946.000000)"
                            id="//-PROJECT-//"
                            stroke={dark ? '#FFF' : colors.primary.default}
                          >
                            <g transform="translate(0.000000, 431.000000)">
                              <g id="Coastal">
                                <g
                                  id="Project-Nav"
                                  transform="translate(990.000000, 511.000000)"
                                >
                                  <g
                                    id="Right-Arrow"
                                    transform="translate(348.000000, 12.000000) rotate(-180.000000) translate(-348.000000, -12.000000) translate(336.000000, 0.000000)"
                                  >
                                    <polyline
                                      id="Path-2"
                                      transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000) "
                                      points="4.5 15.75 12 8.25 19.5 15.75"
                                    />
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </svg>
                    </button>
                  </SliderControls>
                </CarouselWrapper>
              </Grid>
            </Grid>
          </Section>
        )}
      </Consumer>
    )
  }
}
