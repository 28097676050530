import React from 'react'
import styled from 'styled-components'

import { Section } from '../index'
import { Grid } from '../../grid'

import { ContactForm } from '../../form'

import ContactImage from '../../../images/contactPhoto.jpg'

const LandscapeImage = styled.div`
  padding-top: 66%;
  background-image: url("${props => props.image}");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-right: -20px;
  
  @media (max-width: 991px) {
    margin-left: -20px;
  }
  
  @media (min-width: 992px) {
    margin-right: calc(-20.8333333333% + -29px);
  }
`

const Image = styled.div`
  grid-row: 1;

  @media (min-width: 576px) {
    grid-column: 2;
  }
`

export const Contact = props => (
  <Section>
    <Grid
      columns={'1fr'}
      gap={'30px'}
      align={'center'}
      offset={1}
      tablet={{ columns: 'repeat(2, 1fr)', gap: '40px' }}
      desktop={{ columns: 'repeat(2, 1fr)', gap: '40px' }}
    >
      <ContactForm theme={props.theme} />
      <Image>
        <LandscapeImage image={ContactImage} />
      </Image>
    </Grid>
  </Section>
)
