import React from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import Layout from '../components/layout'
import { Grid } from '../components/grid'
import { Capabilities } from '../components/sections/capabilities'
import { CallToAction } from '../components/sections/callToAction'
import { Section } from '../components/sections'
import { Contact } from '../components/sections/contact'
import { CaseStudySlider } from '../components/sections/caseStudySlider'

import styled from 'styled-components'

const Intro = styled.div`
  p:last-of-type {
    margin-bottom: 0;
  }
`

const IndexPage = () => (
  <StaticQuery
    query={graphql`
      query HomePageQuery {
        site {
          siteMetadata {
            title
          }
        }
        caseStudies: allMarkdownRemark(
          sort: { fields: frontmatter___published_on, order: DESC }
          filter: {
            fileAbsolutePath: { regex: "/(/_content/case_studies)/.*.md$/" }
          }
        ) {
          edges {
            node {
              fileAbsolutePath
              html
              frontmatter {
                title
                tags
                images
                quote {
                  quote
                  name
                  title
                }
              }
            }
          }
        }
        services: markdownRemark(frontmatter: { path: { eq: "/services" } }) {
          frontmatter {
            services {
              heading
              homepageCopy
              highlights
            }
          }
        }
        home: markdownRemark(frontmatter: { path: { eq: "/" } }) {
          html
          excerpt(pruneLength: 160)
          frontmatter {
            title
            cta {
              heading
              link {
                text
                url
              }
            }
            seoTitle
            seoDescription
            seoImage
          }
        }
        seo: file(
          relativePath: { eq: "seo.md" }
          sourceInstanceName: { eq: "data" }
        ) {
          data: childMarkdownRemark {
            frontmatter {
              image
              description
              siteTitle
            }
          }
        }
      }
    `}
    render={data => (
      <Layout>
        <Helmet
          htmlAttributes={{ lang: 'en' }}
          meta={[
            {
              name: 'description',
              content:
                data.home.frontmatter.seoDescription ||
                data.home.excerpt ||
                data.seo.data.frontmatter.description,
            },
            {
              name: 'og:image',
              content:
                'https://madebypioneer.com' +
                (data.home.frontmatter.seoImage ||
                  data.seo.data.frontmatter.image),
            },
          ]}
          title={`${data.home.frontmatter.seoTitle ||
            data.home.frontmatter.title} | ${
            data.seo.data.frontmatter.siteTitle
          }`}
        />
        <Section>
          <Grid
            tablet={{ columns: 'repeat(2, 1fr)' }}
            desktop={{ columns: 'repeat(2, 1fr)' }}
            offset={1}
          >
            <Intro>
              <p className="large">
                {data.home.html.replace('<p>', '').replace('</p>', '')}
              </p>
            </Intro>
          </Grid>
        </Section>

        {/* Case Studies */}
        <CaseStudySlider caseStudies={data.caseStudies.edges} />

        {/* Call To Action */}
        <Section>
          <Grid offset={2}>
            <CallToAction
              heading={data.home.frontmatter.cta.heading}
              link={data.home.frontmatter.cta.link}
            />
          </Grid>
        </Section>

        {/* Capabilities */}
        <Capabilities content={data.services.frontmatter.services} />

        {/* Contact Section */}
        <Contact />
      </Layout>
    )}
  />
)

export default IndexPage
