import React from 'react'
import styled from 'styled-components'
import { colors } from '../../../styles/colors'

import { Section } from '../index'
import { Grid } from '../../grid'
import { Card } from '../../card'

const SectionHeading = styled.p`
  color: ${colors.quaternary.default};
`

export const Capabilities = ({ content, props }) => (
  <Section>
    {console.log(props)}
    <Grid offset={1}>
      <SectionHeading className={'blockquote'}>
        Explore Our Capabilities
      </SectionHeading>
    </Grid>

    <Grid
      columns={'1fr'}
      gap={'30px'}
      tablet={{ columns: 'repeat(2,1fr)', gap: '30px' }}
      desktop={{ columns: 'repeat(4,1fr)', gap: '60px' }}
      offset={1}
    >
      {content.map((service, i) => (
        <Card
          key={i}
          to={'/services'}
          twoLines={true}
          heading={service.heading}
          content={service.homepageCopy}
          services={service.highlights}
          theme={content.theme}
        />
      ))}
    </Grid>
  </Section>
)
